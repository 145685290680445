<template>
  <div>
    <GeneralDashboard @notify-parent="searchComplaints" />

    <div
      v-if="!loading"
      class="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 3xl:grid-cols-8 md:gap-x-5 gap-1 justify-around"
    >
      <ComplaintCardMini
        v-for="complaint in complaints"
        :key="complaint.title"
        :title="complaint.title"
        :numComplaints="complaint.numComplaints"
        :percent="complaint.percent"
        :danger="complaint.danger"
        :fall="complaint.fall"
      />
    </div>

    <div v-if="!loading" class="general-report-section">
      <RankingComponent
        v-if="ranking.length > 0"
        :total="totalRanking"
        :ranking="ranking"
        class="ranking"
      />

      <PieChart
        title="Inconformidades con intervenciones de entes de control"
        :data="pieData"
        class="unconformities"
      />

      <RecurrentPetitionersTable
        title="Listado de peticionarios recurrentes "
        :tdata="recurrentPetitioners"
        :page="recurrentPetitionersPage"
        :count="recurrentPetitionersCount"
        @onPageChange="updateRecurrentPetitionersPage"
        class="petitioners"
        :downloadRecurrentPetitioners="recurrentPetitionersDownload"
        :downloadAllRecurrentPetitioners="allRecurrentPetitioners"
      />

      <ComplaintsChartBars
        title="Total de quejas por entidad"
        horizontal
        :datax="categoriesEntity"
        :datay="seriesEntity"
        class="complaints-by-entity"
      />
      <ComplaintsChartBars
        title="Total de quejas por producto"
        :datay="seriesProduct"
        :datax="categoriesProduct"
        class="complaints-by-product"
      />

      <ComplaintTrendsByDate
        title="Tendencia de las quejas por fecha"
        :labelsx="labelsComplaintsDate"
        :line="lineComplaint"
        :bars="barsEntity"
        class="complaints-by-date"
      />
    </div>
    <Extension
      v-if="!loading"
      title=" Tiempos de respuesta y prórrogas por quejas"
      :tdata="complaintsInfo"
      :page="extensionsPage"
      :count="extencionsCount"
      :actualQuery="actualQuery"
      @onPageChange="updateExtensionsPage"
      :downloadExtensions="extensionsDownload"
    />

    <div v-if="loading" class="flex justify-center items-center mt-32">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>

<script>
import Extension from "@/components/Dashboard/Management/Extension";
import GeneralDashboard from "@/components/Dashboard/Management/GeneralDashboard";
import ComplaintCardMini from "@/components/Dashboard/Management/General/ComplaintCardMini";

import RecurrentPetitionersTable from "@/components/Dashboard/Management/General/RecurrentPetitionersTable.vue";

import RankingComponent from "@/components/Dashboard/Management/General/RankingComponent.vue";
import ComplaintResolutionProductivity from "@/components/Dashboard/Management/General/ComplaintResolutionProductivity.vue";

import ComplaintsChartBars from "@/components/Dashboard/Management/General/ComplaintsChartBars.vue";

import PieChart from "@/components/Dashboard/Management/General/PieChart.vue";
import ComplaintTrendsByDate from "@/components/Dashboard/Management/General/ComplaintTrendsByDate.vue";

import moment from "moment";
import capitalize from "@/utils/checkForms.js";
const arrVolumetry = [
  {
    name: "Total de quejas",
    target: "total_codigo_queja",
    percent: "porcentaje_codigo_queja",
  },
  {
    name: "Quejas con Tutela",
    target: "total_tutela",
    percent: "porcentaje_tutela",
  },
  {
    name: "Total Entidades",
    target: "total_nombre_entidad",
    percent: "porcentaje_nombre_entidad",
  },
  {
    name: "Total Usuarios",
    target: "total_numero_id_CF",
    percent: "porcentaje_numero_id_CF",
  },
  {
    name: "Total Ciudades",
    target: "total_codigo_pais",
    percent: "porcentaje_codigo_pais",
  },
  {
    name: "Tiempo total de respuesta",
    target: "sum_secconds",
    percent: "porcentaje_sum_secconds",
  },
  {
    name: "Cantidad de prorrogas",
    target: "total_sum_prorroga_queja",
    percent: "porcentaje_prorroga_queja",
  },
  {
    name: "Tiempo promedio de respuesta",
    target: "avg_secconds",
    percent: "porcentaje_avg_secconds",
  },
];

export default {
  components: {
    Extension,
    GeneralDashboard,
    ComplaintCardMini,
    RankingComponent,

    ComplaintTrendsByDate,
    PieChart,
    ComplaintResolutionProductivity,

    RecurrentPetitionersTable,

    ComplaintsChartBars,
  },
  data() {
    return {
      complaintsInfo: [],
      //Complaints
      complaints: [],

      //Ranking
      ranking: [],
      totalRanking: 0,

      //Pie chart
      pieData: [],

      // recurrent petitioners list
      recurrentPetitioners: [],
      recurrentPetitionersDownload: [],
      allRecurrentPetitioners: [],

      //complaits by product
      seriesProduct: [],
      categoriesProduct: [],

      //complaints by entity
      categoriesEntity: [],
      seriesEntity: [],

      // complaint trends by date
      labelsComplaintsDate: [],
      lineComplaint: {
        name: "Total Quejas",
        type: "line",
        data: [],
      },
      barsEntity: {
        name: "Total Entidades",
        type: "column",
        data: [],
      },

      extensionsPage: 1,
      extencionsCount: 0,
      extensionsDownload: [],
      allExtensions: [],
      recurrentPetitionersPage: 1,
      recurrentPetitionersCount: 0,
      pageSize: 10,

      actualQuery: {},
      loading: false,
    };
  },
  watch: {
    extensionsPage: function (newVal, oldVal) {
      if (newVal == this.$route.query.extensionsPage) {
        return;
      } else {
        this.$router.replace({
          query: { extensionsPage: this.extensionsPage },
        });
        this.queryExtensions();
      }
    },
    recurrentPetitionersPage: function (newVal, oldVal) {
      if (newVal == this.$route.query.recurrentPetitionersPage) {
        return;
      } else {
        this.$router.replace({
          query: { recurrentPetitionersPage: this.recurrentPetitionersPage },
        });
        this.queryRecurrentPetitioners();
      }
    },
  },
  created() {
    if (this.$route.query.page) {
      this.extensionsPage = parseInt(this.$route.query.page);
    } else {
      this.extensionsPage = 1;
    }
    // Se carga por defecto los valores para una semana
    this.searchComplaints({
      filter_model: {
        fecha_creacion: {
          filter_type: "date",
          operator: "AND",
          condition1: {
            date_from: moment()
              .set(new Date())
              .subtract(7, "d")
              .format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "greaterThanOrEqual",
          },
          condition2: {
            date_from: moment().set(new Date()).format("YYYY-MM-DD"),
            date_to: null,
            filter_type: "date",
            type: "lessThanOrEqual",
          },
        },
      },
    });
  },
  methods: {
    searchComplaints(ev) {
      this.showToast("info", "Consultando gráficas...");
      this.loading = true;
      this.actualQuery = ev;
      const promises = [];
      // Construcción de todas las peticiones para la vista de reporte general
      // 1. Petición de volumetría
      const bodyVolumetry = {
        filter_model: this.actualQuery?.filter_model,
      };
      promises.push(this.$api.getVolumetry(bodyVolumetry));
      // 2. Satisfacción
      const bodySatisfaction = {
        filter_model: {
          fecha_creacion: this.actualQuery?.filter_model?.fecha_creacion,
        },
      };
      promises.push(this.$api.getSatisfaction(bodySatisfaction));

      // 4. Total quejas por entidad
      const bodyCompany = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["nombre_entidad"],
        limit: 10,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyCompany));
      // 5. Total de quejas por producto
      const bodyProduct = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["producto_nombre"],
        limit: 5,
        offset: 0,
      };
      promises.push(this.$api.applyFilters(bodyProduct));
      // 6. Tendencias de las quejas por fecha
      const bodyDate = {
        fields_count_distinct: ["nombre_entidad"],
        filter_model: {
          ...this.actualQuery?.filter_model,
          fecha_creacion: this.actualQuery?.filter_model?.fecha_creacion,
        },
        group_model: ["DATE(q.fecha_creacion)"],
        order_model: ["-DATE(q.fecha_creacion)"],
      };
      //delete bodyDate["filter_model"]["fecha_creacion"];
      promises.push(this.$api.applyFilters(bodyDate));
      // 7. Por ente de control
      const bodyEnte = {
        filter_model: this.actualQuery?.filter_model,
        group_model: ["nombre_ente_control"],
      };
      promises.push(this.$api.applyFilters(bodyEnte));

      //promises.push(this.$api.searchComplaints(bodyComplaint));

      // Ejecutamos todas las peticiones al backend
      Promise.allSettled(promises)
        .then((response) => {
          // Volumetría
          const volumetry = response[0]?.value?.data;
          this.complaints = [];
          let factor = 1;
          arrVolumetry.forEach((item) => {
            factor = item.target.includes("secconds") ? 3600 : 1;

            if (volumetry[item.percent] === null) {
              this.complaints.push({
                title: item.name,
                percent: 0,
                numComplaints: 0,
                danger: false,
                fall: false,
              });
            } else {
              this.complaints.push({
                title: item.name,
                percent: +(
                  volumetry[item.percent] < 0
                    ? -1 * volumetry[item.percent]
                    : volumetry[item.percent]
                ).toFixed(2),
                numComplaints: volumetry[item.target] / factor,
                danger: volumetry[item.percent] < 0,
                fall: volumetry[item.percent] < 0,
              });
            }
          });

          // Satisfacción
          this.ranking = [];
          this.totalRanking = 0;
          const satisfaction = response[1]?.value?.data;
          for (let i = 5; i > 0; i--) {
            const auxSatisfaction = satisfaction.find(
              (el) => el.satisfaccion === i
            );
            this.ranking.push({
              name: i,
              count: auxSatisfaction ? auxSatisfaction.conteo : 0,
              percent: auxSatisfaction ? auxSatisfaction.porcentaje : 0,
            });
            this.totalRanking += auxSatisfaction ? auxSatisfaction.conteo : 0;
          }

          // Total de quejas por entidades
          const companies = response[2]?.value?.data?.results;
          const auxCompany = [];
          this.categoriesEntity = [];
          this.seriesEntity = [];
          companies.forEach((item) => {
            this.categoriesEntity.push(item.nombre_entidad + "");
            auxCompany.push(item.total);
          });
          this.seriesEntity.push({ data: auxCompany });

          // Total de quejas por producto
          const products = response[3]?.value?.data?.results;
          const auxProduct = [];
          this.categoriesProduct = [];
          this.seriesProduct = [];
          products.forEach((item) => {
            this.categoriesProduct.push(item.producto_nombre);
            auxProduct.push(item.total);
          });
          this.seriesProduct.push({ data: auxProduct });

          // Tendencia de quejas por fecha
          let complaints_date = response[4]?.value?.data?.results;
          complaints_date = complaints_date.sort(function (a, b) {
            return new Date(a.f0_) - new Date(b.f0_);
          });
          this.labelsComplaintsDate = [];
          this.lineComplaint.data = [];
          this.barsEntity.data = [];
          complaints_date.forEach((item) => {
            this.labelsComplaintsDate.push(item.f0_);
            this.lineComplaint.data.push(item.total);
            this.barsEntity.data.push(item.total_nombre_entidad);
          });

          // Entes de control
          const ente_control = response[5]?.value?.data?.results;;
          let countNotEnte = 0;
          let countYesEnte = 0;
          ente_control.forEach((item) => {
            if (item.nombre_ente_control) {
              countYesEnte += item.total;
            } else {
              countNotEnte += item.total;
            }
          });

          this.pieData = [countYesEnte, countNotEnte];

          this.loading = false;
        })
        .catch((error) => console.error(error));

      // Listado de peticionarios recurrentes
      this.queryRecurrentPetitioners(this.actualQuery);

      //Quejas
      this.queryExtensions();
    },
    queryRecurrentPetitioners(ev = this.actualQuery) {
      // Peticionarios recurrentes
      const bodyRecurring = {
        filter_model: ev?.filter_model,
        limit: this.pageSize,
        offset: this.recurrentPetitionersPage - 1,
        group_model: ["tipo_identificacion_CF", "numero_id_CF", "nombres"],
        type: "recurring",
      };

      this.$api.applyFilters(bodyRecurring).then((response) => {
        const recurrency = response?.data;
        const maxComplaint = recurrency?.results[0]?.total;
        this.recurrentPetitioners = recurrency.results.map((item, index) => {
          return {
            index: index + 1,
            typeId: item.tipo_identificacion_CF,
            identification: item.numero_id_CF,
            username: item.nombres,
            complaints: item.total,
            details: item.details,
            complaintsSolved: +((item.total * 100) / maxComplaint).toFixed(2),
          };
        });
        this.recurrentPetitionersDownload = recurrency.results.map((item) => {
          return (item.details || []).map(detail => {
            return {
              "Tipo de identificación": item.tipo_identificacion_CF,
              Número: item.numero_id_CF,
              Nombre: capitalize(item.nombres),
              "Total de quejas": item.total,
              "Radicado": detail._field_1,
              "Producto": detail._field_2,
              "Motivo": detail._field_3,
            };
          })
        }).flat();
        this.recurrentPetitionersCount = response.data.count;
      });
      this.downloadAllRecurrentPetitioners(ev);
    },
    queryExtensions(ev = this.actualQuery) {
      // Tabla de quejas
      const bodyComplaint = {
        filter_model: ev?.filter_model,
        limit: this.pageSize,
        offset: this.extensionsPage - 1,
      };
      this.$api.searchComplaints(bodyComplaint).then((response) => {
        this.complaintsInfo = response.data.results.map((item) => {
          return {
            ...item,
            prorroga_queja: item.prorroga_queja ? item.prorroga_queja : 0,
            total_segundos: item.total_segundos ? item.total_segundos : 0,
          };
        });
        this.extensionsDownload = response.data.results.map((item) => {
          return {
            Radicado: item.codigo_queja,
            "Fecha de creación": item.fecha_creacion,
            "Fecha de cierre": item.fecha_cierre,
            Entidad: capitalize(item.nombre_entidad),
            "Queja Extress": item.queja_expres,
            Prórroga: item.prorroga_queja ? item.prorroga_queja : 0,
            "Tiempo total":
              (item.total_segundos / 86400).toFixed(0) > 0
                ? (item.total_segundos / 86400).toFixed(0) + " días"
                : (item.total_segundos / 3600).toFixed(0) + " horas",
          };
        });
        this.extencionsCount = response?.data.count;
      });
      this.downloadAllExtensions(ev);
    },
    downloadAllExtensions(ev = this.actualQuery) {
      const bodyComplaint = {
        filter_model: ev?.filter_model,
        offset: this.extensionsPage - 1,
      };
      this.$api.searchComplaints(bodyComplaint).then((response) => {
        this.allExtensions = response?.data?.results?.map((item) => {
          return {
            Radicado: item.codigo_queja,
            "Fecha de creación": item.fecha_creacion,
            "Fecha de cierre": item.fecha_cierre,
            Entidad: capitalize(item.nombre_entidad),
            "Queja Extress": item.queja_expres,
            Prórroga: item.prorroga_queja ? item.prorroga_queja : 0,
            "Tiempo total":
              (item.total_segundos / 86400).toFixed(0) > 0
                ? (item.total_segundos / 86400).toFixed(0) + " días"
                : (item.total_segundos / 3600).toFixed(0) + " horas",
          };
        });
      }).catch(err => console.error(err));
    },
    downloadAllRecurrentPetitioners(ev = this.actualQuery) {
      const bodyRecurring = {
        filter_model: ev?.filter_model,
        offset: this.recurrentPetitionersPage - 1,
        group_model: ["tipo_identificacion_CF", "numero_id_CF", "nombres"],
        type: "recurring",
      };
      this.$api.applyFilters(bodyRecurring).then((response) => {
        const recurrency = response?.data?.results;
        this.allRecurrentPetitioners = recurrency.map((item) => {
          return (item.details || []).map(detail => {
            return {
              "Tipo de identificación": item.tipo_identificacion_CF,
              Número: item.numero_id_CF,
              Nombre: capitalize(item.nombres),
              "Total de quejas": item.total,
              "Radicado": detail._field_1,
              "Producto": detail._field_2,
              "Motivo": detail._field_3,
            };
          })
        }).flat();
      });
    },

    //#endregion

    // #region Actualizar datos de las tabla
    updateRecurrentPetitionersPage(newPage) {
      this.recurrentPetitionersPage = newPage;
    },
    updateExtensionsPage(newPage) {
      this.extensionsPage = newPage;
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    // #endregion
  },
};
</script>

<style scoped>
.general-report-section {
  box-sizing: border-box;
  height: auto;
  display: grid;
  grid-template-columns: repeat(2, minmax(50%, 1fr));
  grid-template-rows: max-content 1fr 45em max-content;
  /* grid-template-rows: repeat(4, minmax(400, 1fr)); */

  grid-gap: 1em;

  grid-template-areas:
    "ranking unconformities"
    "petitioners petitioners"
    "complaints-by-entity complaints-by-product"
    "complaints-by-date complaints-by-date";
}

.ranking {
  grid-area: ranking;
}
.unconformities {
  grid-area: unconformities;
}
.petitioners {
  grid-area: petitioners;
}
.complaints-by-product {
  grid-area: complaints-by-product;
}
.complaints-by-entity {
  grid-area: complaints-by-entity;
}
.complaints-by-date {
  grid-area: complaints-by-date;
}
@media only screen and (max-width: 1024px) {
  .general-report-section {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 40em max-content 40em 40em 500px;
    grid-template-areas:
      "ranking "
      "unconformities"
      "petitioners"
      "complaints-by-entity "
      "complaints-by-product"
      "complaints-by-date";
    grid-gap: 1em;
  }
}
</style>
